<template>
  <div class="page-wrapper">
    <div class="page-form">
      <Logo clickable></Logo>

      <div>
        <b-row v-if="loaded">
          <span class="info-text mt-4 mb-4">
            <b-icon-x-circle v-if="!success" variant="danger"></b-icon-x-circle>
            <b-icon-check-circle
              v-if="success"
              variant="success"
            ></b-icon-check-circle>
            {{ text }}</span
          >
        </b-row>
        <b-row>
          <b-button
            variant="primary"
            class="default-btn mb-3 flex-1"
            v-on:click="go"
          >
            Go To Login
          </b-button>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/shared/Logo.vue";

export default {
  name: "SelfApprove",
  components: {
    Logo
  },
  data() {
    return {
      loaded: false,
      success: false,
      text:
        "You have sucessfully confirmed your account. Click on the button below and start using Nash."
    };
  },
  async mounted() {
    this.$store.commit("SHOW_HEADER", false);
    let response = await this.$store.dispatch("validateSelfApprovalToken", {
      id: this.$route.query.id,
      token: this.$route.query.token
    });
    this.text = response
      ? this.text
      : "There was an error with the token. Try again.";
    this.loaded = true;
    this.success = response;
    if (!this.success) this.$router.push({ path: "/login" });
  },
  methods: {
    go() {
      this.$router.push({ path: "/login" });
    }
  }
};
</script>

<style lang="scss">
.info-text {
  svg {
    margin: 0px 10px;
    width: 32px;
    height: 32px;
  }
}
</style>
